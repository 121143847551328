import Typography from 'antd/es/typography';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { convertHeight, EMeasurementSystem } from 'common/helpers/units-converter.helper';
import { MeasurementContext } from 'common/components/Provider/MeasurementContext';

interface IComponentProps {
  height?: number;
}

export const PlayerHeightBadge = ({ height }: IComponentProps) => {
  const { t } = useTranslation();
  const { measurementSystem } = useContext(MeasurementContext);

  return (
    <Typography.Title level={5} title={t('Height')} className="px-3 py-1 mb-0 player-height-badge radius-round t-align-c">
      {measurementSystem === EMeasurementSystem.Metric
        ? t('cm', { value: height })
        : convertHeight(height, measurementSystem) || '-'}
    </Typography.Title>
  );
};
