import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from 'antd/es/typography';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Button from 'antd/es/button';
import DatePicker from 'antd/es/date-picker';
import { useFormMapper } from '@axmit/antd4-helpers';
import { useMediaPredicate } from 'react-media-hook';
import { LayoutContent } from 'common/components/Layouts/ContentLayout';
import { AvatarEditor } from 'common/components/AvatarEditor/AvatarEditor';
import { GenderSelect } from 'common/components/GenderSelect';
import { Phone } from 'common/components/Phone/Phone';
import { requiredField, phoneRule, passwordRules } from 'common/helpers/filed-rules';
import { clientDateFormat } from 'common/models/dateModels';
import { MEDIA_DESKTOP } from 'common/const/config.const';
import { EMeasurementSystem } from 'common/helpers/units-converter.helper';
import { MeasurementSystemSelector } from 'common/components/Selector/MeasurementSystemSelector';
import { disabledFutureDates, getUTCStartOfDayFromString } from 'common/helpers/date.helper';
import { UserRepresentationTypesSelect } from 'entities/User/components/UserRepresentationTypes';
import { communicationAuth, IAuthConnectedProps } from 'entities/Auth/Auth.communication';
import { communicationUser, IUserConnectedProps } from 'entities/User/User.communication';
import { IUserUpdateModelForm, EUserRepresentationType, EUserGender, IUserUpdatePasswordParams } from 'entities/User/User.models';
import { LanguageSelect } from 'entities/Language/components/LanguageSelect';

type AllProps = IAuthConnectedProps & IUserConnectedProps;

interface IFormUserUpdateInfoValues {
  firstName: string;
  lastName: string;
  gender: EUserGender;
  birthday: string;
  representationType: EUserRepresentationType;
  measurementSystem: EMeasurementSystem;
  phone: string;
  lang: string;
}

interface IFormUserUpdatePasswordValues {
  oldPassword: string;
  password: string;
}

const MentorEditComponent: React.FC<AllProps> = props => {
  const { t } = useTranslation();
  const isDesktop: boolean = useMediaPredicate(`(min-width: ${MEDIA_DESKTOP})`);
  const [avatarId, setAvatarId] = useState<string | null>(null);
  const { authUser, updateUserModel, userModel, getUserModel, updatePasswordUserPasswordModel, userPasswordModel } = props;
  const { data: authUserData } = authUser;
  const { data: userData, params, errors, loading } = userModel;
  const { loading: passwordLoading, params: passwordParams, errors: passwordErrors } = userPasswordModel ?? {};

  const data = useMemo(() => {
    if (userData) {
      const { birthday, representationType, phone, ...rest } = userData;

      return {
        ...rest,
        phone: phone || undefined,
        representationType: representationType || EUserRepresentationType.Parent,
        birthday: birthday ? getUTCStartOfDayFromString(birthday) : undefined
      };
    }

    return null;
  }, [userData]);

  const { fields } = useFormMapper(
    [
      'firstName',
      'lastName',
      'gender',
      'birthday',
      'representationType',
      'phone',
      'oldPassword',
      'password',
      'lang',
      'measurementSystem'
    ],
    data,
    params,
    errors
  );

  const passwordForm = useFormMapper(['oldPassword', 'password'], null, passwordParams, passwordErrors);

  useEffect(() => {
    if (authUserData?.id) {
      getUserModel(authUserData.id);
    }
  }, []);

  useEffect(() => {
    if (userData?.image) {
      setAvatarId(userData.image.id);
    }
  }, [userData]);

  const onFinishUserInfoUpdate = (values: IFormUserUpdateInfoValues) => {
    if (userData?.id) {
      const { firstName, lastName, gender, birthday, representationType, phone, lang, measurementSystem } = values;
      const body: IUserUpdateModelForm = {
        firstName,
        lastName,
        gender,
        measurementSystem,
        representationType,
        phone: phone || null,
        id: userData.id,
        // @ts-ignore потому что пока нет типа null в image
        image: avatarId,
        birthday: null,
        lang
      };

      if (birthday) {
        body.birthday = getUTCStartOfDayFromString(birthday);
      }

      updateUserModel(body);
    }
  };

  const onFinishUserPasswordUpdate = (values: IFormUserUpdatePasswordValues) => {
    if (userData?.id) {
      const { oldPassword, password } = values;
      const body: IUserUpdatePasswordParams = {
        oldPassword,
        password,
        id: userData.id
      };
      updatePasswordUserPasswordModel(body);
    }
  };

  return (
    <LayoutContent rowClassName={`user-edit-page${isDesktop ? '' : '__mobile'}`}>
      <Typography.Title level={3} className={isDesktop ? '' : 'ml-5'}>
        {t('My settings')}
      </Typography.Title>

      <Form
        onFinish={onFinishUserInfoUpdate}
        fields={fields}
        name="update-user-info"
        layout="vertical"
        className="user-edit-page__block p-5 mb-5"
      >
        {isDesktop ? (
          <Row>
            <Col className="mr-5">
              <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
            </Col>
            <Col className="user-edit-page__block__data">
              <Row className="mb-5">
                <Form.Item rules={[requiredField]} name="firstName" label={t('First name')} className="mr-5">
                  <Input disabled={loading} size="large" />
                </Form.Item>
                <Form.Item rules={[requiredField]} name="lastName" label={t('Last name')}>
                  <Input disabled={loading} size="large" />
                </Form.Item>
              </Row>
              <Row className="mb-5">
                <Form.Item rules={[requiredField]} name="gender" label={t('Gender')} className="mr-5">
                  <GenderSelect disabled={loading} size="large" />
                </Form.Item>
                <Form.Item name="birthday" label={t('Birthday')}>
                  <DatePicker
                    disabled={loading}
                    disabledDate={disabledFutureDates}
                    className="width-full"
                    size="large"
                    format={clientDateFormat}
                    placeholder={t('DD MM YYYY')}
                  />
                </Form.Item>
              </Row>
              <Row className="mb-5">
                <Form.Item rules={[requiredField]} name="representationType" label={t('Representation type')} className="mr-5">
                  <UserRepresentationTypesSelect disabled={loading} size="large" />
                </Form.Item>
                <Form.Item rules={[phoneRule]} name="phone" label={t('Phone')}>
                  <Phone disabled={loading} />
                </Form.Item>
              </Row>
              <Row gutter={[16, 0]}>
                <Col span={12}>
                  <Form.Item rules={[requiredField]} name="lang" label={t('Language')}>
                    <LanguageSelect disabled={loading} size="large" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="measurementSystem" label={t('Measurement system')}>
                    <MeasurementSystemSelector className="width-full" />
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Button htmlType="submit" type="primary" size="large" disabled={loading} loading={loading}>
                  {t('Save')}
                </Button>
              </Row>
            </Col>
          </Row>
        ) : (
          <>
            <Row className="flex-noWrap mb-5">
              <Col className="mr-5">
                <AvatarEditor onChange={setAvatarId} avatarId={avatarId} />
              </Col>
              <Col>
                <Row>
                  <Form.Item rules={[requiredField]} name="firstName" className="mb-3">
                    <Input disabled={loading} size="large" />
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item rules={[requiredField]} name="lastName" className="mb-0">
                    <Input disabled={loading} size="large" />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
            <Form.Item rules={[requiredField]} name="gender" label={t('Gender')} className="width-full mb-5">
              <GenderSelect disabled={loading} size="large" />
            </Form.Item>
            <Form.Item name="birthday" label={t('Birthday')} className="width-full mb-5">
              <DatePicker
                disabled={loading}
                className="width-full"
                disabledDate={disabledFutureDates}
                size="large"
                format={clientDateFormat}
                placeholder={t('DD MM YYYY')}
              />
            </Form.Item>
            <Form.Item
              rules={[requiredField]}
              name="representationType"
              label={t('Representation type')}
              className="width-full mb-5"
            >
              <UserRepresentationTypesSelect disabled={loading} size="large" />
            </Form.Item>
            <Form.Item rules={[phoneRule]} name="phone" label={t('Phone')} className="width-full mb-5">
              <Phone disabled={loading} />
            </Form.Item>
            <Form.Item rules={[requiredField]} name="lang" label={t('Language')} className="mb-5">
              <LanguageSelect disabled={loading} size="large" />
            </Form.Item>
            <Form.Item name="measurementSystem" label={t('Measurement system')}>
              <MeasurementSystemSelector className="width-full" />
            </Form.Item>
            <Button block htmlType="submit" type="primary" size="large" disabled={loading} loading={loading}>
              {t('Save')}
            </Button>
          </>
        )}
      </Form>
      <div className={`user-edit-page__block user-edit-page__block-left-empty${isDesktop ? '-desktop' : ''} py-5 mb-5`}>
        <Row className="mb-3">
          <Typography.Text>{t('Email')}</Typography.Text>
        </Row>
        <Row>
          <Input disabled size="large" value={data?.email} />
        </Row>
      </div>
      <Form
        onFinish={onFinishUserPasswordUpdate}
        fields={passwordForm.fields}
        name="update-user-password"
        layout="vertical"
        className={`user-edit-page__block user-edit-page__block-left-empty${isDesktop ? '-desktop' : ''} py-5`}
      >
        <Form.Item rules={[requiredField, passwordRules]} name="oldPassword" label={t('Current password')} className="mb-5">
          <Input.Password type="password" size="large" disabled={passwordLoading} />
        </Form.Item>
        <Form.Item rules={[requiredField, passwordRules]} name="password" label={t('New complex password')} className="mb-5">
          <Input.Password type="password" size="large" disabled={passwordLoading} />
        </Form.Item>
        <Row justify="start">
          <Button
            block={!isDesktop}
            htmlType="submit"
            type="primary"
            size="large"
            disabled={passwordLoading}
            loading={passwordLoading}
          >
            {t('Save')}
          </Button>
        </Row>
      </Form>
    </LayoutContent>
  );
};

export const MentorEditPage = communicationUser.injector(communicationAuth.injector(MentorEditComponent));
